import $, { data } from "jquery";

class DataLayerController {
  constructor() {
    if (typeof dataLayer !== "undefined" && dataLayer !== null) {
      this.init();
    }
  }
  init() {
    this.listenFormValidation();
  }

  listenFormValidation() {
    document.addEventListener(
      "wpcf7mailsent",
      (event) => {
        this.sendFormValidationData();
        this.sendNewsletterData();
      },
      false
    );
  }

  sendFormValidationData() {
    let data = null;

    // get event
    const $formEvent = document.querySelector('[name="data-layer-hidden"]');
    const formEvent = $formEvent ? $formEvent.getAttribute("value") : null;

    if (formEvent) {
      data = {
        event: formEvent,
      };
    }

    // get object
    const $formObject = document.querySelector('[name="objet"]');
    const formObject = $formObject ? $formObject.value : null;

    if (data && formObject) {
      data.formSubject = formObject;
    }

    // if data not empty send it
    if (data) {
      window.dataLayer.push(data);
      console.log("sendFormValidationData", data);
    }
  }

  sendNewsletterData() {
    // is checkbox newsletter checked ?
    const $checkbox = document.querySelector(
      '[name="documentation"], [name="newsletter"]'
    );
    const isNewsletterChecked = $checkbox ? $checkbox.checked : null;

    if (isNewsletterChecked) {
      window.dataLayer.push({
        event: "inscription-offres-success",
      });
      console.log("isNewsletterChecked");
    }
  }
}
export default DataLayerController;
