console.log("%c ", "color: #000000; font-size: 14px");
console.log(
  "%cIRM",
  "background-color: #000000; color: #FFFFFF; font-size: 14px; text-transform: uppercase; line-height: 1.25; padding:16px 25px; border-radius: 25px"
);
console.log("%c ", "color: #000000; font-size: 14px");
console.log("%cLe Petit Garage", "color: #000000;");
console.log("%c✌️", "font-size: 50px");

import $ from "jquery";

// object-fit polyfill
import objectFitImages from "object-fit-images";
objectFitImages();

// Grab all focal point images
const $imgFocalPoint = $("img.js-focal-point-image");

$(window)
  .on("load", (e) => {
    $imgFocalPoint.css("max-width", "none").responsify();
  })
  .on("resize", (e) => {
    $imgFocalPoint.responsify();
  });

const $body = $(document.body);

$(".header")
  .on("click", ".js-toggle-menu", (e) => {
    e.preventDefault();
    $(e.currentTarget).trigger("toggle.menu");
  })
  .on("toggle.menu", (e) => {
    $body.toggleClass("menu-open");
    if (!$body.hasClass("menu-open")) {
      $body.removeClass("sub-menu-open");
      $(".header-menu-main-nav-item", e.delegateTarget).removeClass("active");
      $(".header-menu-main-sub-nav-item", e.delegateTarget).removeClass(
        "active"
      );
    }
  })
  .on("click", ".header-menu-main-nav-item", (e) => {
    // only on mobile menu
    if (!window.matchMedia("(max-width: 991px)").matches) {
      return;
    }

    $body.addClass("sub-menu-open");
    $(e.currentTarget).addClass("active");
  })
  .on(
    "click",
    ".header-menu-main-nav-item.active .header-menu-main-nav-link",
    (e) => {
      // only on mobile menu
      if (!window.matchMedia("(max-width: 991px)").matches) {
        return;
      }

      e.stopPropagation();
      e.preventDefault();

      $body.removeClass("sub-menu-open");
      $(e.currentTarget)
        .closest(".header-menu-main-nav-item")
        .removeClass("active");
    }
  );
/* $('.menu-item-has-children').on('click', (e) => {
  e.preventDefault()
}) */

$("#panorama-view").on("click", ".js-panorama-view-toggle", (e) => {
  $(e.delegateTarget).attr("data-view", $(e.target).data("view"));
});

import "slick-carousel";
$(() =>
  $(".gallery").slick({
    infinite: true,
    arrows: true,
    dots: false,
    autoplay: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: false,
  })
);

//ticking machine
var percentTime;
var tick;
var time = 1;
var progressBarIndex = 0;

$(".progressBarContainer .progressBar").each(function (index) {
  var progress = "<div class='inProgress inProgress" + index + "'></div>";
  $(this).html(progress);
});

function startProgressbar() {
  $(".progressBarContainer .progressBar").closest("div").removeClass("active");
  $('.progressBarContainer span[data-slick-index="' + progressBarIndex + '"]')
    .closest("div")
    .addClass("active");

  resetProgressbar();
  percentTime = 0;
  tick = setInterval(interval, 10);
}

function interval() {
  if (
    $(
      '.slider .slick-track div[data-slick-index="' + progressBarIndex + '"]'
    ).attr("aria-hidden") === "true"
  ) {
    progressBarIndex = $('.slider .slick-track div[aria-hidden="false"]').data(
      "slickIndex"
    );
    startProgressbar();
  } else {
    percentTime += 1 / (time + 5);
    $(".inProgress" + progressBarIndex).css({
      height: percentTime + "%",
    });
    if (percentTime >= 100) {
      $(".single-item").slick("slickNext");
      progressBarIndex++;
      if (progressBarIndex > 2) {
        progressBarIndex = 0;
      }
      startProgressbar();
    }
  }
}

function resetProgressbar() {
  $(".inProgress").css({
    height: 0 + "%",
  });
  clearInterval(tick);
}
startProgressbar();
// End ticking machine

$(".progressBarContainer div").click(function () {
  clearInterval(tick);
  var goToThisIndex = $(this).find("span").data("slickIndex");
  $(".single-item").slick("slickGoTo", goToThisIndex, false);
  startProgressbar();
});

import "chocolat";
$(() =>
  $(".chocolat-gallery").Chocolat({
    imageSize: "contain",
    loop: true,
    enableZoom: false,
  })
);

import "menu-aim";
$(() =>
  $(".menu-submenu").menuAim({
    submenuSelector: ".has-drop",
    activate: (row) => {
      const $row = $(row);
      $row.toggleClass("active", $row.hasClass("has-drop"));
    },
    deactivate: (row) => {
      $(row).removeClass("active");
    },
  })
);

// Configurator

const $configurators = $(".configurator");
const $wording_gamme = $(".wording_gamme");

//show first configurator
if ($(".configurator-gamme-switcher").length) {
  $configurators.hide();
  $configurators.first().show();

  if ($wording_gamme.length) {
    $wording_gamme.hide();
    $wording_gamme.first().show();
  }

  $(".configurator-gamme-switcher").on("click", (e) => {
    $configurators.hide();
    $configurators
      .filter('[data-gamme="' + $(e.target).data("gamme") + '"]')
      .show();

    if ($wording_gamme.length) {
      $wording_gamme.hide();
      $wording_gamme
        .filter('[data-gamme="' + $(e.target).data("gamme") + '"]')
        .show();
    }

    // $('.configurator-gamme-switcher').removeClass('active')
    // $(e.target).addClass('active');

    $(e.delegateTarget).attr("data-pos", $(e.target).data("pos"));
  });
}

let toits = [];

for (var i = 0; i < $configurators.length; i++) {
  const $configurator = $($configurators[i]);

  toits[$configurator.data("gamme")] = false;

  if ($(".configurator-configuration.toit.active", $configurator).length) {
    toits[$configurator.data("gamme")] = $(
      ".configurator-configuration.toit.active",
      $configurator
    ).data("type-toit");
  }

  const $renders = $(".configurator-render-mask", $configurator);
  const $configurations = $(".configurator-configuration", $configurator);

  $configurations.filter("[data-render-src]").each((j, config) => {
    // preload render image for each configuration
    const src = $(config).data("render-src");
    const img = new Image();
    img.src = src;
  });

  $configurations.filter("[data-render-src-plat]").each((j, config) => {
    // preload render image for each configuration
    const src = $(config).data("render-src-plat");
    const img = new Image();
    img.src = src;
  });

  $configurations.filter("[data-render-src-pente]").each((j, config) => {
    // preload render image for each configuration
    const src = $(config).data("render-src-pente");
    const img = new Image();
    img.src = src;
  });
  $configurator.on("click", ".configurator-configuration", (e) => {
    e.preventDefault();

    let $configuration = false;

    if ($(e.currentTarget).hasClass("toit")) {
      $(".toit", $configurator).removeClass("active");
      $(e.currentTarget).addClass("active");
      toits[$configurator.data("gamme")] = $(e.currentTarget).data("type-toit");

      $configuration = $(
        ".configurator-configuration.active:not(.toit)",
        $configurator
      );
    } else {
      $configuration = $(e.currentTarget);
    }

    let renderSrc = false;
    if (toits[$configurator.data("gamme")]) {
      renderSrc = $configuration.data(
        "render-src-" + toits[$configurator.data("gamme")]
      );
    } else {
      renderSrc = $configuration.data("render-src");
    }
    const renderId = $configuration.data("render-id");

    $configurations
      .filter('[data-render-id="' + renderId + '"]')
      .removeClass("active");

    $configuration.addClass("active");

    $renders.length &&
      $renders
        .removeClass("active")
        .filter('[data-render-id="' + renderId + '"]')
        .css("background-image", renderSrc ? "url(" + renderSrc + ")" : "none")
        .addClass("active");
  });
}

import Util from "bootstrap.util";
global.Util = Util;
import "bootstrap.modal";

typeof window.wpcf7 !== "undefined" &&
  $("#modal").on("hidden.bs.modal", (e) => {
    // clear WPCF7 form response inside modal
    window.wpcf7.clearResponse($("form", e.relatedTarget));
  });

/******* popin youtube *******/

import { trailerFilm } from "./utilities/bootstrap/_trailerFilm";
import { readmore } from "./utilities/bootstrap/_readmoreless";

document.addEventListener("DOMContentLoaded", (event) => {
  trailerFilm();
  readmore();

  /******* end popin youtube *******/

  /******* obfuscation *******/
  var classname = document.getElementsByClassName("atc");
  for (var i = 0; i < classname.length; i++) {
    //click gauche
    classname[i].addEventListener("click", myFunction, false);
    //click droit
    classname[i].addEventListener("contextmenu", myRightFunction, false);
  }
  //fonction du click gauche
  var myFunction = function (event) {
    var attribute = this.getAttribute("data-atc");
    if (event.ctrlKey) {
      var newWindow = window.open(
        decodeURIComponent(window.atob(attribute)),
        "_blank"
      );
      newWindow.focus();
    } else {
      document.location.href = decodeURIComponent(window.atob(attribute));
    }
  };
  //fonction du click droit
  var myRightFunction = function (event) {
    var attribute = this.getAttribute("data-atc");
    if (event.ctrlKey) {
      var newWindow = window.open(
        decodeURIComponent(window.atob(attribute)),
        "_blank"
      );
      newWindow.focus();
    } else {
      window.open(decodeURIComponent(window.atob(attribute)), "_blank");
    }
  };
  /******* end obfuscation *******/

  /******* sticky nav bar *******/

  if (document.getElementById("gamme__nav")) {
    // When the user scrolls the page, execute myFunction
    window.onscroll = function () {
      stickyNavBar();
    };

    // Get the navbar
    var navbar = document.getElementById("gamme__nav");
    var footer = document.getElementById("footer");

    // Get the offset position of the navbar
    var sticky = navbar.offsetTop + navbar.offsetHeight;

    // Get the offset position of the limit
    var limit = footer.offsetTop;

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function stickyNavBar() {
      // console.log(window.pageYOffset + window.innerHeight);
      // console.log(sticky);
      // console.log(limit);
      // console.log('__________');
      if (
        window.pageYOffset + window.innerHeight >= sticky &&
        window.pageYOffset + window.innerHeight <= limit
      ) {
        // console.log('aaaa');
        navbar.classList.add("sticky");
      } else {
        // console.log('bbb');
        navbar.classList.remove("sticky");
      }
    }

    /******* sticky nav bar -- smoothscrollto *******/
    $("a.js-anchor").on("click", function (e) {
      e.preventDefault();

      const id = $(this).attr("href").split("#")[1];
      const yOffset = document.getElementById("header").offsetHeight;
      const element = document.getElementById(id);
      // console.log(id)
      const y =
        element.getBoundingClientRect().top + window.pageYOffset - yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    });
    /******* end sticky nav bar -- smoothscrollto *******/
  }

  /******* end sticky nav bar *******/
});

/******* obfuscation *******/
document.addEventListener("DOMContentLoaded", (event) => {
  var classname = document.getElementsByClassName("atc");
  for (var i = 0; i < classname.length; i++) {
    //click gauche
    classname[i].addEventListener("click", obfuscation_left_click, false);
    //click droit
    classname[i].addEventListener(
      "contextmenu",
      obfuscation_right_click,
      false
    );
  }
});
//fonction du click gauche
var obfuscation_left_click = function (event) {
  var attribute = this.getAttribute("data-atc");
  if (event.ctrlKey || this.classList.contains("atc-new_window")) {
    var newWindow = window.open(
      decodeURIComponent(window.atob(attribute)),
      "_blank"
    );
    newWindow.focus();
  } else {
    document.location.href = decodeURIComponent(window.atob(attribute));
  }
};
//fonction du click droit
var obfuscation_right_click = function (event) {
  var attribute = this.getAttribute("data-atc");
  if (event.ctrlKey || this.classList.contains("atc-new_window")) {
    var newWindow = window.open(
      decodeURIComponent(window.atob(attribute)),
      "_blank"
    );
    newWindow.focus();
  } else {
    window.open(decodeURIComponent(window.atob(attribute)), "_blank");
  }
};
/******* end obfuscation *******/

import Configurator from "./utilities/Configurator";
new Configurator();

import DataLayerController from "./utilities/DataLayerController";
new DataLayerController();

// improve cf7 submit
/**
 * Disable WPCF7 button while it's submitting
 * Stops duplicate enquiries coming through
 */
let intervalId;
document.addEventListener( 'wpcf7submit', function( event ) {

    // find only disbaled submit buttons
    var button = $('.wpcf7-submit[disabled]');

    // grab the old value
    var old_value = button.attr('data-value');

    // enable the button
    button.prop('disabled', false);

	clearInterval(intervalId);
    // put the old value back in
    button.val(old_value);
	button.css("cursor", "pointer");

}, false );

$('form.wpcf7-form').on('submit',function() {

    var form = $(this);
    var button = form.find('input[type=submit]');
    var current_val = button.val();

    // store the current value so we can reset it later
    button.attr('data-value', current_val);

    // disable the button
	button.prop("disabled", true);

	button.val("⏳");
	button.css("cursor", "wait");

    // tell the user what's happening
	intervalId = setInterval(() => {
		var new_val = (button.val() === "⏳") ? "⌛" : "⏳";
		button.val(new_val);
	}, 1000);


});