import $ from "jquery";

class Configurator {
  constructor() {
    this.init();
  }
  init() {
    this.listenRoofClick();
    this.listenFaceClick();
    this.listenVariantClick();
  }
  listenVariantClick() {
    $("body").on("click", ".configurator_v2__variant", (e) => {
      const $variant = $(e.currentTarget);
      this.handleVariantClick($variant);
    });
  }
  handleVariantClick($variant) {
    const $parent = $variant.closest(".configurator_v2");
    const layerSrc = $variant.attr("data-variant");
    $parent.find(".configurator_v2__variant").removeClass("active");
    $variant.addClass("active");
    this.changeLayer(layerSrc, "variant", $parent);
  }
  listenRoofClick() {
    $("body").on("click", ".configurator_v2__roof", (e) => {
      const $roof = $(e.currentTarget);
      this.handleRoofClick($roof);
    });
  }
  handleRoofClick($roof) {
    const $parent = $roof.closest(".configurator_v2");
    const roofId = $roof.attr("data-roof");
    const vegetationSrc = $roof.attr("data-vegetation");
    const backgroundSrc = $roof.attr("data-background");

    // change layer
    $parent.find(".configurator_v2__roof").removeClass("active");
    $roof.addClass("active");
    this.changeLayer(vegetationSrc, "vegetation", $parent);
    this.changeLayer(backgroundSrc, "background", $parent);

    // change faces options
    $parent.find(".configurator_v2__faces").removeClass("active");
    const $activeFaceOptions = $parent.find(
      `.configurator_v2__faces[data-roof="${roofId}"]`
    );
    $activeFaceOptions.addClass("active");
    this.handleFaceClick(
      $activeFaceOptions.find(".configurator_v2__face").first()
    );
  }
  listenFaceClick() {
    $("body").on("click", ".configurator_v2__face", (e) => {
      const $face = $(e.currentTarget);
      this.handleFaceClick($face);
    });
  }
  handleFaceClick($face) {
    const $parent = $face.closest(".configurator_v2");
    const roofId = $face.attr("data-roof");
    const faceId = $face.attr("data-face");

    $parent.find(".configurator_v2__face").removeClass("active");
    $face.addClass("active");

    // change variants options
    $parent.find(".configurator_v2__variants").removeClass("active");
    const $activeVariantOptions = $parent.find(
      `.configurator_v2__variants[data-roof="${roofId}"][data-face="${faceId}"]`
    );
    $activeVariantOptions.addClass("active");
    this.handleVariantClick(
      $activeVariantOptions.find(".configurator_v2__variant").first()
    );
  }

  changeLayer(src, type, $parent) {
    $parent.find(`.configurator_v2__layer--${type}`).attr("src", src);
  }
}
export default Configurator;
