export function trailerFilm() {

    var restartTrailer = false;
    var youtubeID = 0;

    window.launchVideo = function (_youtubeID) {

        youtubeID = _youtubeID;
        createModalYoutube();

        // on charge une seule fois le script youtube
        if (restartTrailer == false) {
            loadYouTubeScript();
        } else {
            // si on clique à noiuveau sur le bouton "Bande annonce", on relance manuellement le script Youtube
            onYouTubeIframeAPIReady();
        }
    }

    // Fermer la modale
    window.closeModal = function () {
        var modal = document.getElementById("modal-youtube");
        if (modal) {
            modal.remove(); // supprime la modale
        }

        // Si on veut, on recommence !
        restartTrailer = true;
        return restartTrailer;
    }



    // 1 - Crée une fenêtre modale
    function createModalYoutube() {

        var modal = document.createElement('div');
        modal.setAttribute("id", "modal-youtube");
        modal.setAttribute("onclick", "closeModal();");

        // L'élément "player" sera transformé automatique en iframe par le script de Youtube
        var player = document.createElement('div');
        player.setAttribute("id", "player");

        var player_container = document.createElement('div');
        player_container.setAttribute("id", "player_container");

        // var close = document.createElement('button');
        // close.setAttribute("id", "close-youtube");
        // close.innerHTML = "Fermer x";
        // close.setAttribute("onclick", "closeModal();");

        document.body.appendChild(modal);
        // document.getElementById("modal-youtube").appendChild(close);
        document.getElementById("modal-youtube").appendChild(player_container);
        document.getElementById("player_container").appendChild(player);

        // On peut fermer la fenêtre avec la touche "Escape"
        document.addEventListener('keydown', function (event) {
            if (event.key === "Escape") {
                closeModal();
            }
        });

    }

    // @source : https://developers.google.com/youtube/iframe_api_reference?hl=fr#Loading_a_Video_Player
    // 2. This code loads the IFrame Player API code asynchronously.
    function loadYouTubeScript() {

        if (typeof (YT) == 'undefined' || typeof (YT.Player) == 'undefined') {

            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            // var player;

            // Le script Youtube lance cette fonction automatiquement dans le document
            window.onYouTubePlayerAPIReady = function () {
                onYouTubeIframeAPIReady();
            }

        } else {
            onYouTubeIframeAPIReady();
        }
    }

    // 3. This function creates an <iframe> (and YouTube player)
    //    after the API code downloads.

    function onYouTubeIframeAPIReady() {

        var trailer = findTrailerId();
        var player;
        // console.log(document.getElementById("player"));
        player = new YT.Player('player', {
            height: '360',
            width: '640',
            videoId: trailer,
            playerVars: {
                'modestbranding': 1,
                'autoplay': 1,
                //'controls': 0,
                'rel': 0,
            },

            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
            }
        });
    }

    function findTrailerId() {
        // On récupère l'url de la vidéo - elle est stocké dans le bouton #cta-trailer
        // var cta = document.querySelector('#cta-trailer');
        // var url = cta.dataset.trailer;
        // var identifiant = YouTubeGetID(url);
        console.log(youtubeID);
        return youtubeID;
    }

    // Transforme l'url Youtube en identifiant
    // source : https://gist.github.com/takien/4077195
    // function YouTubeGetID(url) {
    //     url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    //     if (url[2] !== undefined) {
    //         var ID = url[2].split(/[^0-9a-z_\-]/i);
    //         ID = ID[0];
    //     }
    //     else {
    //         var ID = url;
    //     }
    //     return ID;
    // }

    // 4. The API will call this function when the video player is ready.
    function onPlayerReady(event) {
        event.target.playVideo();
    }

    // 5. The API calls this function when the player's state changes.
    function onPlayerStateChange(event) { }

    function stopVideo() {
        player.stopVideo();
    }
}